<template>
  <div class="rated">
    <Header />
    <div class="rated-content">
      <div class="rated-title">{{info.infoTitle}}</div>
      <div class="rated-subtitle">
        <div>
          <span>文章作者：</span>
          <span>{{info.pushUser}}</span>
        </div>
        <div>
          <span>发布时间：</span>
          <span>{{timeParser(info.createTime)}}</span>
        </div>
      </div>
      <div class="rated-bg">
        <img :src="info.coverUrlName" alt="" style="width:100%;height:100%;">
      </div>
      <!-- <p class="rated-text">
        近日，记者从两江新区获悉，艾尔伴家科技有限公司（以下简称“艾尔伴家”）收到了由重庆市科学技术局、重庆市财政局、
        国家税务总局重庆市税务局联合颁发的“高新技术企业”证书，成功获得“国家高新技术企业”认定。
      </p> -->
      <div class="rated-text" v-html="info.infoContent"></div>
      <div class="footer" @click="jumpTo">下一篇：海归创业>></div>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import {  information_info } from '@/api/media'
  import { parseTime } from '@/utils/tool'
  export default {
    name: 'Rated',
    components: { Header },
    data() {
      return {
        info: {}
      }
    },
    created() {
      information_info({id: 21}).then(res => {
        if(res.code === 0) {
            this.info = res .data
          }
      })
    },
    methods: {
      jumpTo() {
        this.$router.push('/returnees')
      },
      timeParser(time) {
        return parseTime(new Date(time), '{y}-{m}-{d} {h}:{i}')
      }
    }
  }
</script>

<style scoped>
.rated-content{
  margin-top: 1.16rem;
  padding: 0.59rem 0.4rem 0.65rem 0.4rem;
  box-sizing: border-box;
}
.rated-title{
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.65rem;
}
.rated-subtitle{
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}
.rated-subtitle > div{
  flex: 1;
}
.rated-bg{
  width: 100%;
  height: 5.73rem;
  margin-top: 0.65rem;
  margin-bottom: 0.84rem;
}
.rated-text{
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 0.64rem;
  text-indent: 2em
}
.footer{
  height: 1rem;
  position: fixed;
  bottom: 0rem;
  right: 0.43rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  background: #fff;
  width: 100%;
  text-align: right;
  line-height: 1rem;
}
</style>